import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { authStore, sceneStore } from 'stores';
import { AUTH_TOKENS_KEY } from 'stores/authStore';

import { RouterPathEnum } from 'shared';

import './RootLayout.scss';

const cnRootLayout = block('RootLayout');

const RootLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthorized = authStore.checkIsAuthorized();

    if (!isAuthorized) {
      authStore.updateToken();
      navigate(RouterPathEnum.Auth);
    }
  }, []);

   if (window.location.pathname !== RouterPathEnum.Scene) {
    sceneStore.setUser('');
    sceneStore.setGradientMuscle([]);
    sceneStore.deInit();
   }

  useEffect(() => {
    if (!authStore.currentUser) navigate(RouterPathEnum.Auth);
  }, [authStore.currentUser]);

  useEffect(() => {
    if (!localStorage.getItem(AUTH_TOKENS_KEY)) {
      navigate(RouterPathEnum.Auth);
    }
    const fourMinutes = 1000 * 60 * 4;

    if (authStore.tokenLoading) {
      authStore.updateToken();
    }

    const interval = setInterval(() => {
      if (localStorage.getItem(AUTH_TOKENS_KEY)) {
        authStore.updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [localStorage.getItem(AUTH_TOKENS_KEY), authStore.tokenLoading]);

  return (
    <div className={cnRootLayout()}>
      <Outlet />
    </div>
  );
};

export default observer(RootLayout);
