import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { PatientsApiService } from 'services';

import { User } from 'pages/DoctorPersonalPage/DoctorPersonalPage';

import './UserTable.scss';

interface UserTableProps {
  users: User[];
  doctors: User[];
  setUserClear: any;
  onDelete: (user: User) => void;
}

const UserTable: React.FC<UserTableProps> = ({ users,doctors, setUserClear, onDelete }) => {
  const [notice,setNotice] = useState<any>(null);
  const handlerSubmit=(async ()=>{
    const files: any = [];
    users.map(user => files.unshift([doctors.find((doctor)=>doctor.last_name +' '+ doctor.first_name === user.option)?.id,user.id]));
    try{
      const data = await PatientsApiService.postTask(files);
      if (data) {
        setNotice(`Пациенты успешно распределены`);
        setUserClear([]);
        const timer = setTimeout(()=>{
          setNotice(null)
        },5000)
        return(()=>{
          clearTimeout(timer);
        })
      }
    }catch(e){
      console.log(e)
    }
  })
  return(
  <form>
    <TableContainer sx={{fontWeight:'bold'}} className='table-title'>Распределение пациентов</TableContainer>
    <Table  className='table'>
      <TableHead >
        <TableRow >
          <TableCell sx={{fontWeight:'bold'}} align='center'>Имя</TableCell>
          <TableCell sx={{fontWeight:'bold'}} align='center'>Фамилия</TableCell>
          <TableCell sx={{fontWeight:'bold'}} align='center'>Логин</TableCell>
          <TableCell sx={{fontWeight:'bold'}} align='center'>Email</TableCell>
          <TableCell sx={{fontWeight:'bold'}} align='center'>Ответственный врач</TableCell>
          <TableCell sx={{fontWeight:'bold'}} align='center'>Удалить из списка</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user, index) => (
          <TableRow  key={index}>
            <TableCell align='center'>{user.first_name}</TableCell>
            <TableCell align='center'>{user.last_name}</TableCell>
            <TableCell align='center'>{user.username}</TableCell>
            <TableCell align='center'>{user.email}</TableCell>
            <TableCell align='center'>{user.option}</TableCell>
            <TableCell align='center'>
              <button className='delete' onClick={() => onDelete(user)}>Удалить</button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    {notice && <div style={{color:`green`,textAlign:`center`}}>{notice}</div>}
    <Button variant='contained' sx={{display:'flex',margin:'auto'}} disabled={users.length === 0} onClick={handlerSubmit}>Отправить</Button>
  </form>
)};

export default UserTable;
