import * as React from 'react';
import { useEffect } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore, patientsStore } from 'stores';

import AttemptList from 'pages/MainPage/components/AttemptList/AttemptList';
import DatesList from 'pages/MainPage/components/DatesList/DatesList';
import Levels from 'pages/MainPage/components/Levels/Levels';
import PatientsList from 'pages/MainPage/components/PatientsList/PatientsList';

import './Modal.scss';

const cnModal = block('Modal');
function Modal() {
  const [open, setOpen] = React.useState(false);
  const [switched, setSwitched] = React.useState(true);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (open && patientsStore.patients.length == 0) {
      patientsStore.getPatients();
    }
  
  }, [open]);

  const DrawerList = (
    <Box sx={{ width: '60rem' }} role="presentation">
      <CloseIcon className='closeicon' onClick={()=>setOpen(false)} />
      <List sx={{ paddingTop: 0 }}>
        {dashboardStore.Switcher ? (
          <div className={cnModal()}>
        <Box className={cnModal('pageContent').toString()}>
          <Stack className={cnModal('patientsList').toString()}>
            <PatientsList dynamic={true} switched={switched} setSwitched={setSwitched} />
          </Stack>
          <Stack className={cnModal('Levels').toString()}>
            <Levels dynamic={true} />
          </Stack>
        </Box>
        </div>
      ): (<div className={cnModal()}>
          <Box className={cnModal('pageContent').toString()}>
            <Stack className={cnModal('patientsList').toString()}>
              <PatientsList dynamic={true} switched={switched} setSwitched={setSwitched} />
            </Stack>
            <Stack className={cnModal('DatesList').toString()}>
              <DatesList />
            </Stack>
            <Stack className={cnModal('AttemptList').toString()}>
              <AttemptList />
            </Stack>
            <Stack className={cnModal('Levels').toString()}>
              {patientsStore.selectedPatientAttempt && <Levels dynamic={false} />}
            </Stack>
          </Box>
        </div>)
        }
        
      </List>
    </Box>
  );

  return (
    <div>
      <Button sx={{ color: 'white' }} onClick={toggleDrawer(true)}>
        <ArticleIcon /> Настройки
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}


export default observer(Modal)
