import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { sceneStore } from 'stores';

import  SceneApiService  from '../../services/api/SceneApiService';

import './ScenePage2.scss';

export interface Hands{
  first_PowerLeft:any;
  first_PowerRight:any;
  last_PowerLeft:any;
  last_PowerRight:any;
  midle_PowerLeft:any;
  midle_PowerRight:any;
}
const cnScenePage = block('ScenePage2');

const ScenePage2 = () => {
  const sceneWrapperRef = useRef<HTMLDivElement>(null);
  const user = sceneStore.user;
  const [currentLevel, setCurrentLevel] = useState(1);
  const [currentData, setCurrentData] = useState('first');
  const Levels = [1, 2, 3, 4, 5, 6];

  const checkIntersection = (e: PointerEvent) => {
    if (!sceneWrapperRef.current || !e.isPrimary) return;
    // Логика проверки пересечения
  };

  const fetchData = useCallback(async (dataKey: string) => {
    sceneStore.deInit(); // Вызываем deInit при загрузке данных
    try {
      const data: any = await SceneApiService.postTask(user, `level${currentLevel}`);
      const leftKey = `${dataKey}_PowerLeft`;
      const rightKey = `${dataKey}_PowerRight`;
      const hands = { ...data[leftKey], ...data[rightKey] };
      sceneStore.setGradientMuscle(hands);
      sceneStore.initModel();
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }

    return () => {
      // Функция очистки для deInit при размонтировании компонента
      sceneStore.deInit();
    }
  }, [user, currentLevel]);

  useEffect(() => {
    if (sceneWrapperRef.current) {
      sceneStore.setMuscleClear();
      fetchData(currentData);
      sceneStore.initScene(sceneWrapperRef.current);
      sceneWrapperRef.current.addEventListener('pointermove', checkIntersection);
    }

    return () => {
      // Функция очистки для deInit при размонтировании компонента
      sceneStore.deInit();
      if (sceneWrapperRef.current) {
        sceneWrapperRef.current.removeEventListener('pointermove', checkIntersection);
      }
    };
  }, [fetchData, currentData]);

  useEffect(() => {
    const onResize = () => {
      if (sceneWrapperRef.current) {
        sceneStore.updateSceneSizes(
          sceneWrapperRef.current.clientWidth,
          sceneWrapperRef.current.clientHeight,
        );
      }
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const handleLevelChange = (newLevel: number) => {
    setCurrentLevel(newLevel);
  };

  const handleDateChange = (date: string) => {
    setCurrentData(date);
  };

  useEffect(() => {
    if (currentData) {
      sceneStore.deInit()
      fetchData(currentData);
    }

    return () => {
      // Функция очистки для deInit при размонтировании компонента
      sceneStore.deInit();
    }
  }, [currentData, fetchData]);

  const changeCameraPosition = (direction: string) => {
    sceneStore.setCameraPosition(direction);
  };

  return (
    <>
      <div className="dashboard">
      {/* <Button
              key={'first'}
              variant="outlined"
              onClick={() => sceneStore.deInit()}
            >
              Очистка?
            </Button> */}
        <div>
        Даты:

            <Button
              key={'first'}
              variant="outlined"
              onClick={() => handleDateChange("first")}
            >
              Первая дата
            </Button>
            <Button
              key={'midle'}
              variant="outlined"
              onClick={() => handleDateChange("midle")}
            >
              Средняя дата
            </Button>
            <Button
              key={'last'}
              variant="outlined"
              onClick={() => handleDateChange("last")}
            >
              Последняя дата
            </Button>
            </div>
            <div>
            <Button
              key={'l90'}
              variant="outlined"
              onClick={() => changeCameraPosition('left')}
            >
              Повернуть влево
            </Button>
            <Button
              key={'R90'}
              variant="outlined"
              onClick={() => changeCameraPosition('right')}
            >
              Повернуть вправо
            </Button>
            <Button
              key={'toTop'}
              variant="outlined"
              onClick={() => changeCameraPosition('top')}
            >
              Повернуть вверх
            </Button>
            <Button
              key={'toBot'}
              variant="outlined"
              onClick={() => changeCameraPosition('bottom')}
            >
              Повернуть вниз
            </Button>
            </div>
            <div>
              Уровни:
              {Levels.map((level: number) => {
              return (
                <Button
                  key={level}
                  variant="outlined"
                  onClick={() => handleLevelChange(level)}
                >
                  {level}
                </Button>
              );
            })}

            </div>

      </div>
      <div className={cnScenePage()} ref={sceneWrapperRef}>
        <div className="keyMscle">
          Текущий уровень: {currentLevel}
            <br/>
            Текущая дата: {currentData}
            <br/>
            Пациент: {user}

        </div>
      </div>
    </>
  );
};

export default observer(ScenePage2);