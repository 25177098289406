import { List, ListItemButton, ListItemText } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import patientsStore from 'stores/patientsStore';

import './DatesList.scss';

const cnDatesList = block('DatesList');

const DatesList = () => {
  const onClick = (Date: string) => {
    patientsStore.selectPatientDate(Date);
  };

  return (
    <>
      {!!patientsStore.patientData?.length ? (
        <List className={cnDatesList('nav').toString()}>
          Даты:
          {patientsStore.patientData.map((UserDates: { date: string }) => {
            return (
              <ListItemButton
                key={UserDates.date}
                className={cnDatesList('Dates').toString()}
                selected={patientsStore.selectedPatientDate === UserDates.date}
                onClick={() => onClick(UserDates.date)}
              >
                <ListItemText primary={UserDates.date} />
              </ListItemButton>
            );
          })}
        </List>
      ) : (
        patientsStore.patientData != null && <List>Нет данных</List>
      )}
    </>
  );
};

export default observer(DatesList);
