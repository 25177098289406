import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Link, Tab, Tabs } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { authStore, dashboardStore, patientsStore, sceneStore } from 'stores';

import MainPageHeader from 'pages/MainPage/components/MainPageHeader/MainPageHeader';
import { Loader } from 'components/CommonComponents';
import { RouterPathEnum } from 'shared';
import { IGestureInfoDto } from 'shared/interfaces';

import { DataTable, StatisticsComponent } from './components';

import './DashboardPage.scss';
import Footer from 'pages/MainPage/components/Footer';

const cnDashboardPage = block('DashboardPage');

enum DashboardPageTabs {
  TableTab,
  StatisticsPage,
  Gesture,
  Strength,
}

export enum DashboardPageHand {
  Right = 'right',
  Left = 'left',
}

const tabs = [
  {
    key: DashboardPageTabs.TableTab,
    value: 'Таблица',
  },
  {
    key: DashboardPageTabs.StatisticsPage,
    value: 'Статистика',
  },
  {
    key: DashboardPageTabs.Strength,
    value: 'Энергия',
  },
  {
    key: DashboardPageTabs.Gesture,
    value: 'Жесты',
  },
];

export const testApiData: IGestureInfoDto[] = [
  {
    subject: 'Количество попыток',
    Count: 8,
    fullMark: 10,
  },
  {
    subject: 'Медиана',
    Count: 7.2,
    fullMark: 10,
  },
  {
    subject: 'Количество расскрашенных элементов',
    Count: 1,
    fullMark: 10,
  },
];

export const testData = (hand: string) => {
  if (!!patientsStore.userDataGesture){
   
  return [
    {
      subject: 'Количество попыток',
      Count: patientsStore.userDataGesture[`data_${hand}_g_num_rows`],
      CountDate2: patientsStore.userDataGesture[`data_${hand}_g1_num_rows`],
      CountDate3: patientsStore.userDataGesture[`data_${hand}_g2_num_rows`],
      fullMark: 10,
    },
    {
      subject: 'Медиана',
      Count: patientsStore.userDataGesture[`data_${hand}_g_medians`],
      CountDate2: patientsStore.userDataGesture[`data_${hand}_g1_medians`],
      CountDate3: patientsStore.userDataGesture[`data_${hand}_g2_medians`],
      fullMark: 10,
    },
    {
      subject: 'Количество элементов',
      Count: patientsStore.userDataGesture[`data_${hand}_g_repeat`],
      CountDate2: patientsStore.userDataGesture[`data_${hand}_g1_repeat`],
      CountDate3: patientsStore.userDataGesture[`data_${hand}_g2_repeat`],
      fullMark: 10,
    },
  ];
}
};

const DashboardPage = () => {
  const navigate = useNavigate();
    sceneStore.setUser(patientsStore.patient?.user.username);

  const [currentTab, setCurrentTab] = useState<DashboardPageTabs>(1);
  const systemData = {
    result: [
      dashboardStore.dataLoader,
      dashboardStore.dataSecondLoader,
      dashboardStore.dataThirdLoader,
    ],
    power_: [dashboardStore.PowerLoader, dashboardStore.Power2Loader, dashboardStore.Power3Loader],
    gesture_: [
      dashboardStore.GestureLoader,
      dashboardStore.GestureLoader2,
      dashboardStore.GestureLoader3,
    ],
  };
  const onChangeTab = (_event: SyntheticEvent, newValue: number) => {
    dashboardStore.clearSelectedJoint();
    if (newValue != 2) {
      dashboardStore.selectedGesture = null;
    } else {
      changeHand(dashboardStore.jointHand!)
    }
    setCurrentTab(newValue);
  };

  const changeHand = (hand: 'left'| 'right') => {
      patientsStore.isLoading = true;
    systemData['result'].map((dataLoader, i: number) => {
      dataLoader.openJsonFile();
      dataLoader.openJsonFile(patientsStore.patientDateCSV[hand + (i === 0 ? '' : `_${i}`)]);
    });
    systemData['power_'].map((dataLoader, i: number) => {
      dataLoader.parsePowerData(
        patientsStore.patientDateCSV['power_' + hand + (i === 0 ? '' : `_${i}`)],
      );
    });
    systemData['gesture_'].map((dataLoader, i: number) => {
      dataLoader.parseGestureData(
        patientsStore.patientDateCSV['gesture_' + hand + (i === 0 ? '' : `_${i}`)],
      );
    });

    dashboardStore.setJointUpdate(hand);
    dashboardStore.setStats(patientsStore.patientDateCSV[`stats_${dashboardStore.jointHand}`]);
    if (!!patientsStore.userDataGesture){

    const allValues = [
      patientsStore.userDataGesture[`data_${hand}_g_num_rows`],
      patientsStore.userDataGesture[`data_${hand}_g1_num_rows`],
      patientsStore.userDataGesture[`data_${hand}_g2_num_rows`],
      patientsStore.userDataGesture[`data_${hand}_g1_medians`],
      patientsStore.userDataGesture[`data_${hand}_g2_medians`],
      patientsStore.userDataGesture[`data_${hand}_g_medians`],
      patientsStore.userDataGesture[`data_${hand}_g_repeat`],
      patientsStore.userDataGesture[`data_${hand}_g1_repeat`],
      patientsStore.userDataGesture[`data_${hand}_g2_repeat`],
    ];
    const filtredAllValues = allValues
      .map((item) => {
        return parseInt(item);
      })
      .sort(function (a, b) {
        return b - a;
      });
    dashboardStore.setSizeRadarChartValue(filtredAllValues[0]);
    dashboardStore.setSelectedGesture('bla', testData(hand));
    }
    patientsStore.isLoading = false;
  };

  const onClickLeft = () => {
    changeHand(DashboardPageHand.Left);
  };

  const onClickRight = () => {
    changeHand(DashboardPageHand.Right);
  };

  const getTab = () => {
    switch (currentTab) {
      case DashboardPageTabs.StatisticsPage: {
        return <StatisticsComponent  />;
      }
      case DashboardPageTabs.TableTab: {
        return (
          <DataTable
            isLoading={dashboardStore.dataLoader.isLoading}
            data={dashboardStore.dataLoader.tableData}
            columns={dashboardStore.dataLoader.tableHeader}
          />
        );
      }
      case DashboardPageTabs.Strength: {
        return (
          <StatisticsComponent
            tab="power"
            columnsNames={
              !!(dashboardStore.Stats?.length)
                ? ['Мыщца', 'Энергия', 'Ср. дата', 'Последняя дата']
                : ['Мыщца', 'Энергия']
            }
          />
        );
      }
      case DashboardPageTabs.Gesture: {
        return (
          <StatisticsComponent
            tab="gesture"
            columnsNames={
              !!Object.keys(dashboardStore.GestureLoader2.data).length
                ? ['Жест', `Медиана За первую дату`, 'За среднюю дату', 'За последнюю дату']
                : ['Жест', 'Медиана']
            }
          />
        );
      }
    }
  };

  useEffect(() => {
    
    if (patientsStore.patientDateCSV[DashboardPageHand.Left]) {
      patientsStore.isLoading = true;
      changeHand(
        patientsStore.patient?.defeat == '1' ? DashboardPageHand.Left : DashboardPageHand.Right,
      );
      
      setCurrentTab(DashboardPageTabs.Gesture);
      patientsStore.isLoading=false;
    }
  }, [patientsStore.patientDateCSV]);

  return (
    <div className={cnDashboardPage()}>
      <MainPageHeader />
      <div className={cnDashboardPage('header')}>
        
        <Tabs
          value={currentTab}
          onChange={onChangeTab}
          className={cnDashboardPage('tabs').toString()}
        >
          {tabs.map((tab) => (
            <Tab
              label={tab.value}
              value={tab.key}
              key={tab.key}
              className={cnDashboardPage('tab').toString()}
            />
          ))}
        </Tabs>
        {patientsStore.patient?.user.username && <Link
          className={cnDashboardPage('link').toString()}
          variant="body1"
          onClick={() => navigate(RouterPathEnum.Scene)}
        >
          3D Model
        </Link>}
        <div className='lvl1'>
        <div className={cnDashboardPage('buttons').toString()}>
          Выбор руки:
          <div className={cnDashboardPage('buttonWrap').toString()}>
            <Button
              variant="contained"
              className={cnDashboardPage('button').toString()}
              onClick={onClickLeft}
              disabled={dashboardStore.jointHand == 'left' ? true : false}
            >
              Левая
            </Button>
            <span style={{ display: patientsStore.patient?.defeat == '1' ? undefined : 'none' }}>
              Поврежденная
            </span>
          </div>
          <div className={cnDashboardPage('buttonWrap').toString()}>
            <Button
              variant="contained"
              className={cnDashboardPage('button').toString()}
              onClick={onClickRight}
              disabled={dashboardStore.jointHand == 'right' ? true : false}
            >
              Правая
            </Button>
            <span style={{ display: patientsStore.patient?.defeat == '1' ? 'none' : undefined }}>
              Поврежденная
            </span>
          </div>
        </div>
        </div>
      </div>
        <Loader visible={patientsStore.isLoading} />
      {authStore.error && <div className='err'>У пациента нет данных для этого уровня или они не полные</div>}
      <div className={cnDashboardPage('content')}>{getTab()}</div> 
      
    </div>
  );
};

export default observer(DashboardPage);
