import { makeAutoObservable, toJS } from 'mobx';

import { IJointInfoDto } from '../../interfaces';

export class DataPowerModel3 {
  public isLoading = false;

  public data: Record<string, IJointInfoDto> = {};

  public tableHeader: string[] = [];
  public tableData: number[][] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async parsePowerData(file?: any) {
    if (file == undefined) return (this.data = {});

    try {
      this.isLoading = true;
      const headerKeys = file[0]?.filter((line: string | any[]) => line.length > 0);
      const tableData = new Array(file[1].length);

      for (let i = 0; i < file[1].length; i++) {
        tableData[i] = file[1].map((val: string) => {
          const parsedVal = parseFloat(val);

          return isNaN(parsedVal) ? val : parseFloat(parsedVal.toFixed(2));
        });

        const headerKey = headerKeys[i];

        this.data[headerKey] = {
          data: [],
          mean: 0,
          std: 0,
          ucl: 0,
          lcl: 0,
          usl: 0,
          lsl: 0,
          min: 0,
          max: 0,
          ooc: [],
        };

        toJS(this.data[headerKey].data.push(tableData[i][i]));
      }
      this.tableHeader = headerKeys;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }
}
