import { makeAutoObservable } from 'mobx';
import {
  AmbientLight,
  Box3,
  Color,
  PerspectiveCamera,
  PointLight,
  Scene,
  Vector2,
  Vector3,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';

import { SceneUtils } from 'shared';
import { ISceneStore } from 'shared/interfaces';
import { HandProcessingModel } from 'shared/models';

const isDebug = false;

const stats = new Stats();

isDebug && document.body.appendChild(stats.dom);

class SceneStore implements ISceneStore {
  public mainCanvas: HTMLCanvasElement;
  public context: WebGLRenderingContext;

  public controls: OrbitControls;

  public scene = new Scene();
  public muscle:any[] = [];
  public camera: PerspectiveCamera = new PerspectiveCamera();

  private readonly renderer: WebGLRenderer;
  private effectComposer: EffectComposer;
  public outlinePass: OutlinePass | null = null;

  public Musculs: any = [];

  public gradientMuscle: any = null;

  public handModel: HandProcessingModel | null = null;

  private lights: PointLight[] = [];

  public user:string = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    const canvas = document.createElement('canvas');

    canvas.style.position = 'absolute';
    canvas.style.left = '0';
    canvas.style.top = '0';
    canvas.style.zIndex = '0';

    this.mainCanvas = canvas;
    this.context = this.mainCanvas.getContext('webgl2') || new WebGLRenderingContext();

    this.controls = new OrbitControls(this.camera, canvas);
    this.renderer = new WebGLRenderer({ canvas: this.mainCanvas, context: this.context });
    this.renderer.shadowMap.enabled = false;
    this.effectComposer = new EffectComposer(this.renderer);

  }

  public setMuscle(muscle:string){
    this.Musculs.push(muscle);
  }

  public setUser(user:string){
    this.user = user;
  }
  public setMuscleClear(){
    this.Musculs = [];
  }

  public setGradientMuscle(muscles:any){
    this.gradientMuscle = muscles;
  }

  public setActiveMuscle(muscle:string){
    this.muscle.push(muscle);
  }

  public setActiveMuscleNull(){
    this.muscle = [];
  }

  public zoomTo(bb: Box3, zoomFactor = 1) {
    const boxSize = bb.getSize(new Vector3());

    const maxDimension = Math.max(boxSize.x, boxSize.y, boxSize.z);
  
    this.camera.position.set(
      maxDimension * zoomFactor*2,
      maxDimension * 1,
      maxDimension * zoomFactor,
    );

  }

  public setCameraPosition(direction: string) {
    if (direction === 'left'){
        this.scene.rotation.y +=Math.PI / 2; 
    } else if (direction === 'right') {
      this.scene.rotation.y -=Math.PI / 2; 
    } else if (direction === 'top') {
      this.scene.rotation.z +=Math.PI / 2; 
    } else {
      this.scene.rotation.z -=Math.PI / 2; 
    }
    this.render();

  }

  public initModel() {
     if (this.handModel) {
      this.deInit();
     }; 
     console.log(this.scene.children)
    this.handModel = new HandProcessingModel(this);
    this.handModel.init(this.lights);
  }

  public initLight() {
    const ambidenLight = new AmbientLight(0xffffff);
    this.scene.add(ambidenLight)
  }

  public render() {
    requestAnimationFrame(this.render.bind(this));

    isDebug && stats.begin();

    this.controls.update();

    this.effectComposer.render();

    isDebug && stats.end();
  }

  public updateSceneSizes(width: number, height: number) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    // Update renderer
    this.renderer.setSize(width, height);
    this.effectComposer.setSize(width, height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  public initScene(wrapper: HTMLDivElement) {
    wrapper.prepend(this.mainCanvas);

    this.scene.background = new Color(0.68, 0.68, 0.68);

    this.updateSceneSizes(wrapper.clientWidth, wrapper.clientHeight);

    this.camera.position.z = 1;
    this.controls.update();

    this.initLight();

    const renderPass = new RenderPass(this.scene, this.camera);
    this.effectComposer.addPass(renderPass);

    this.outlinePass = new OutlinePass(
      new Vector2(wrapper.clientWidth, wrapper.clientHeight),
      this.scene,
      this.camera,
    );
    this.outlinePass.visibleEdgeColor = new Color(0.7, 0.7, 0.7);
    this.outlinePass.hiddenEdgeColor = new Color(0.15, 0.15, 0.15);
    this.outlinePass.edgeStrength = 10;
    this.effectComposer.addPass(this.outlinePass);

    (window as any).scene = this.scene;

    this.render();
  }

  public checkIntersection(mousePosition: Vector2,name?:string) {
    if (!this.outlinePass) return;

  
    SceneUtils.raycaster.setFromCamera(mousePosition, this.camera);
    const intersects = SceneUtils.raycaster.intersectObject(this.scene);

    this.handModel?.updateModelByIntersections(intersects,name);
  }

  public clearMucles() {
    this.handModel?.clearPrevSelectedObjectHighlight();
  }

  public deInit() {
    this.scene.clear();
    this.lights = [];
    this.handModel = null;
  
  }
}

export default new SceneStore();
