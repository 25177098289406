/* eslint-disable prefer-const */
import { makeAutoObservable } from 'mobx';
import {
  /*  AmbientLight, */
  Box3,
  Color,
  Intersection,
  Mesh,
  MeshPhysicalMaterial,
  PointLight,
  Vector3,
  Vector4,
} from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { sceneStore } from 'stores';

import { HandModelMeshNames, MuscleHighlight } from 'shared/enums';
import { ISceneStore } from 'shared/interfaces';
import { MuscleMaterial } from 'shared/materials';
import { ColorUtils } from 'shared/utils';

import modelPath from  "../../../assets/models/scene.glb";

export class HandProcessingModel {
  private sceneStore: ISceneStore;

  private modelStructures: Record<string, Mesh> = {};

  constructor(sceneStore: ISceneStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.sceneStore = sceneStore;
  }

  public init(lights: PointLight[]) {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    
    dracoLoader.setDecoderPath('/js/libs/draco-new/');
    loader.setDRACOLoader(dracoLoader);

    loader.load(modelPath, (gltf) => {
      const modelData: any = gltf.scene;
      const meshNames = [];
      if(this.sceneStore.scene.children.length > 1) {
        this.sceneStore.scene.children.length = 1
      }
      console.log( this.sceneStore.scene.children)
      this.sceneStore.scene.remove(modelData);
      this.sceneStore.scene.add(modelData);
      const bb = new Box3();
      const lightsInfo = lights.map((light) => ({
        position: light.position.clone(),
        color: new Vector3(light.color.r, light.color.g, light.color.b),
        intensity: light.intensity,
      }));
      const accamulateResults: any = [];
      for (const child of modelData.children) {
        const collectIsMeshObjects = (obj: any): any[] => {
          let result: any = [];
          // Проверяем, есть ли свойство isMesh у текущего объекта
          if (obj.isMesh) {
            result.push(obj);
          }

          // Если есть свойство children и это массив, проходимся по каждому элементу
          if (Array.isArray(obj.children)) {
            for (let child of obj.children) {
              // Рекурсивно собираем объекты с isMesh из дочерних объектов
              result = result.concat(collectIsMeshObjects(child));
            }
          }

          return result;
        };

        for (const mesh of collectIsMeshObjects(child)) {
          bb.expandByObject(mesh);
          const currentMesh = mesh as Mesh;
          accamulateResults.push(mesh);
          if (currentMesh.name === HandModelMeshNames.Bones) {
            currentMesh.material = new MeshPhysicalMaterial({
              color: new Color(0.89, 0.85, 0.79),
              reflectivity: 0.5,
              roughness: 0.4,
            });

            continue;
          }
        
          if (mesh.name === sceneStore.muscle) {
            currentMesh.material = new MuscleMaterial(new Vector4(0, 0, 139, 1.0), lightsInfo);
            this.sceneStore.outlinePass!.selectedObjects = [currentMesh];
          } else {
           

            const regexp = /[а-яё]/i;
           
           if(regexp.test(mesh.name)) {
            
            meshNames.push(mesh.name)

            const colorMesh = ColorUtils.generateGradient(sceneStore.gradientMuscle[mesh.name]);
            currentMesh.material = new MuscleMaterial(
              new Vector4(colorMesh[0], colorMesh[1], colorMesh[2], 1.0),
              lightsInfo,
            );
             
              if(mesh.name.includes('_2')){
                currentMesh.material = new MuscleMaterial(
                  new Vector4(0.502, 0.502, 0.502, 1.0),
                  lightsInfo,
                );
              }} else{
              currentMesh.material = new MuscleMaterial(
                new Vector4(0.961, 0.871, 0.702, 1.0),
                lightsInfo,
              );
            }
          }
           this.modelStructures[currentMesh.name] = currentMesh;
        //   class ArrayComparer {
        //     private array1: string[];
        //     private array2: string[];
        
        //     constructor(array1: string[], array2: string[]) {
        //         this.array1 = array1;
        //         this.array2 = array2;
        //     }
        
        //     public findMissingElements(): { missingInArray2: string[]; missingInArray1: string[] } {
        //         const setB = new Set(this.array2);
        
        //         // Элементы из array1, которые отсутствуют в array2
        //         const missingInArray2 = this.array1.filter(element => !setB.has(element));
        
        //         const setA = new Set(this.array1);
        //         // Элементы из array2, которые отсутствуют в array1
        //         const missingInArray1 = this.array2.filter(element => !setA.has(element));
        
        //         return {
        //             missingInArray2,
        //             missingInArray1
        //         };
        //     }
        // }
        // const keys =Object.keys(sceneStore.gradientMuscle);
        // // Пример использования
        // const arrayA: string[] = meshNames;
        // const arrayB: string[] = keys;
        
        // const comparer = new ArrayComparer(arrayA, arrayB);
        // const result = comparer.findMissingElements();
        
        // console.log("Элементы из Model, которые отсутствуют в Даныые с бэка:", result.missingInArray2); // [1, 2, 3]
        // console.log("Элементы из Даныые с бэка, которые отсутствуют в Model:", result.missingInArray1, arrayA); // [6, 7, 8]
        
        }
       
      }
      
      this.sceneStore.zoomTo(bb); 
    });
  }

  updateModelByBtns(name: any) {
    sceneStore.setActiveMuscle(name);
    const selectedObject = this.sceneStore.handModel.modelStructures[name];
    this.sceneStore.outlinePass!.selectedObjects.push(selectedObject);
    const material = selectedObject.material as MuscleMaterial;
    if (selectedObject.name !== name) {
      this.clearPrevSelectedObjectHighlight();
    } else {
      material.updateHighlight(MuscleHighlight.HasHighlight);
    }
  }

  public clearPrevSelectedObjectHighlight() {
    if (this.sceneStore.outlinePass && this.sceneStore.outlinePass.selectedObjects.length) {
      const objects = this.sceneStore.outlinePass.selectedObjects;
      objects.map((object) => {
        const material = (object as Mesh).material as MuscleMaterial;

        if (object.name !== HandModelMeshNames.Skeleton)
          material.updateHighlight(MuscleHighlight.NoHighlight);
      });
    }
  }

  updateModelByIntersections(intersects: Intersection[], name?: string) {
    if (!this.sceneStore.outlinePass) return;

    if (intersects.length > 0) {
      this.clearPrevSelectedObjectHighlight();

      const selectedObject = intersects[0].object as Mesh;

      const material = selectedObject.material as MuscleMaterial;
      if (selectedObject.name !== HandModelMeshNames.Skeleton) {
        if (selectedObject.name !== name) {
          this.clearPrevSelectedObjectHighlight();
        } else {
          material.updateHighlight(MuscleHighlight.HasHighlight);

          this.sceneStore.outlinePass.selectedObjects = [selectedObject];
        }
      }
    } else {
      this.clearPrevSelectedObjectHighlight();

      this.sceneStore.outlinePass.selectedObjects = [];
    }
  }
}
