import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { authStore } from 'stores';

import Modal from 'components/CommonComponents/Modal/Modal';
import { RouterPathEnum } from 'shared';

import { ReactComponent as SsmuLogo } from '../../../../Icons/ssmuLogo.svg';

import './MainPageHeader.scss';

const cnMainPageHeader = block('MainPageHeader');


const MainPageHeader = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const settings = [{ title: 'Профиль'}, { title: 'Выйти', method: authStore.clearUserInfo }];

  return (
    <AppBar className={cnMainPageHeader().toString()} position="static">
      <Container sx={{ maxWidth: '100%' }} maxWidth={false}>  
        <Toolbar disableGutters sx={{ justifyContent: 'space-between',alignItems:'center' }}>
          
          <Box sx={{ display: location.pathname === '/' ? 'none' : { xs: 'flex' } }}>
            <Modal />
          </Box>
          <Box onClick={()=>navigate(RouterPathEnum.Main)}>
             <SsmuLogo className="logo" /> <span>Система виртуальная для восстановительного лечения нарушений мелкой моторики "НЕЙРО-KINESIS</span>
          </Box>
          

          <Box className={`profile`} sx={{ flexGrow: 0 }}>
            <Tooltip title="Открыть настройки">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Test User" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => {
                const onClick = () => {
                  if (setting.method) setting.method();
                  if (setting.method == undefined) navigate(RouterPathEnum.Profile);
                  handleCloseUserMenu();
                };


                return (
                  <MenuItem key={setting.title} onClick={onClick}>
                    <Typography textAlign="center">{setting.title}</Typography>
                  </MenuItem>
                );
              })}
            </Menu>
            <div className='ver'>Версия приложения 6.54</div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default observer(MainPageHeader);
