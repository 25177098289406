import { /* ChangeEvent, */ useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Box, /* Button, */ Grid, /* TextField, */ Typography } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore, patientsStore } from 'stores';

import { PatientInfo } from 'pages/DashboardPage/components/StatisticsComponent/components';
import PieChartWithNeedle from 'pages/DashboardPage/components/StatisticsComponent/components/BoxPlotChart/PieChartWithNeedle/PieChartWithNeedle';
import { testData } from 'pages/DashboardPage/DashboardPage';
import { MainPageHeader, PatientsList } from 'pages/MainPage/components';
import { Loader, PanelWrapper } from 'components';

import Levels from './Levels';

import './ProfilePage.scss';

const cnProfilePage = block('ProfilePage');

const ProfilePage = () => {
  /* const [textArea, setTextArea] = useState<any>(''); */
  const [level, setLevel] = useState<number>(1);

  useEffect(() => {
    if (!patientsStore.client) {
      patientsStore.getProfile();
    }

    if (patientsStore.client?.status == '2') {
      const setData1 = setTimeout(() => {
        patientsStore.selectPatient(patientsStore.client!.id!);
        patientsStore.selectPatientDynamicCSV('level' + level);
      }, 2000);

      const setData = setTimeout(() => {
        dashboardStore.setSelectedGesture('bla', testData('left'));
      }, 3000);

      return () => {
        clearTimeout(setData);
        clearTimeout(setData1);
      };
    }
  }, [patientsStore.client]);

  const changeLevel = (lvl: any) => {
    setLevel(lvl);
    patientsStore.selectPatientDynamicCSV('level' + lvl);
  };

  useEffect(() => {
    dashboardStore.setSelectedGesture('bla', testData('left'));
  }, [patientsStore.patientDateCSV]);

  /* const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextArea(event.target.value);
  }; */

  return (
    <Box className={cnProfilePage.toString()}>
      <MainPageHeader />
      <Typography sx={{ margin: 'auto', width: 'fit-content' }} variant="h2">
        Приветствуем в личном кабинете
      </Typography>

      <Loader visible={patientsStore.isLoading} />

      <Grid container spacing={2} className={cnProfilePage('container').toString()}>
        <Grid item xs={3} height={'100%'} className={cnProfilePage('item').toString()}>
          <PatientInfo patient={patientsStore.client} />
        </Grid>
        <Grid item xs={4} height={'100%'} className={cnProfilePage('item').toString()}>
          {patientsStore.client?.status == '1' && (
            <PanelWrapper>
              <Typography sx={{ width: 'fit-content', margin: 'auto' }} variant="body1">
                Список ваших пациентов
              </Typography>
              <PatientsList />
            </PanelWrapper>
          )}
          {patientsStore.client?.status == '2' && (
            <PanelWrapper>
              <Typography sx={{ width: 'fit-content', margin: 'auto' }} variant="body1">
                Ваш прогресс
              </Typography>
              {dashboardStore.selectedGesture?.jointData[1] && <PieChartWithNeedle />}
            </PanelWrapper>
          )}
        </Grid>
        {patientsStore.client?.status == '2' && (
          <Grid item xs={4} height={'100%'} className={cnProfilePage('item2').toString()}>
            <PanelWrapper>
              <Typography sx={{ width: 'fit-content', margin: 'auto' }} variant="body1">
                Выберите уровень, за который хотите увидеть статистику, текущий уровень: {level}
              </Typography>
              <Levels onClick={changeLevel} />
            </PanelWrapper>

            <PanelWrapper>
              <Typography sx={{ width: 'fit-content', margin: 'auto' }} variant="body1">
                Вы стали расскрашивать:{' '}
                <b>{dashboardStore.selectedGesture?.jointData[2]?.CountDate2} элементов</b>
              </Typography>
            </PanelWrapper>
            <PanelWrapper>
              <Typography sx={{ width: 'fit-content', margin: 'auto' }} variant="body1">
                Число попыток, которые понадобились для расскрашивания:{' '}
                <b>{dashboardStore.selectedGesture?.jointData[0]?.CountDate2}</b>
              </Typography>
            </PanelWrapper>
            <PanelWrapper>
              <Typography sx={{ width: 'fit-content', margin: 'auto' }} variant="body1">
                <InfoIcon /> Значения берутся за последний день прохождения игры
              </Typography>
            </PanelWrapper>
          </Grid>
        )}

        {/*  <Grid item xs={4} height={'100%'} className={cnProfilePage('item').toString()}>
          <PanelWrapper>
            {patientsStore.client?.status == '1' && (
              <Typography sx={{ width: 'fit-content', margin: 'auto' }} variant="body1">
                Написать пациенту
              </Typography>
            )}
            {patientsStore.client?.status == '2' && (
              <Typography sx={{ width: 'fit-content', margin: 'auto' }} variant="body1">
                Ответить врачу
              </Typography>
            )}
            <TextField multiline rows={2} value={textArea} onChange={onChange} />
            <Button
              disabled={textArea === '' ?? false}
              variant="contained"
              className={cnProfilePage('btn').toString()}
            >
              Отправить сообщение
            </Button>
          </PanelWrapper>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default observer(ProfilePage);
